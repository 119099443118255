import {
	createApp
} from 'vue'
// 引入路由
import router from './route/index.js'
import axios from './config/axios.js';
// import vConsole from './utils/vconsole'
import { SwipeCell } from 'vant';
import { List } from 'vant';
import { Cell, CellGroup } from 'vant';
import 'lib-flexible'
import "vant/lib/index.css"
import App from './App.vue'
const app = createApp(App);
app.use(router)
.use(SwipeCell)
.use(List)
.use(Cell)
.use(CellGroup)
   .mount('#app')
app.config.globalProperties.$axios = axios;
