import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
// import { createWebHistory } from 'vue-router'

const history = createWebHistory()
// const history = createWebHashHistory() // 采用这个路径上有#

const router = createRouter({
	history, // 路由模式
	routes: [{
		path: '/',
		name: 'Index',
		component: () => import('@/views/Index.vue')
	}]
})
export default router
